<template>
  <div class="activity_home" v-if="hd.id">
    
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(image, index) in hd.banner" :key="index">
        <img v-lazy="image" width="100%"/>
      </van-swipe-item>
    </van-swipe>
    
    <div class="countDown">
      <van-count-down :time="time">
        <template #default="timeData">
          <div class="text" v-if="hd.state==2">活动已结束</div>
          <label v-else>
            <span>距{{hd.state==0?'开始':'结束'}}：</span>
            <span class="block">{{ timeData.days }}</span>
            <span class="colon">天</span>
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">时</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">分</span>
            <span class="block">{{ timeData.seconds }}</span>
            <span class="colon">秒</span>
          </label>
        </template>
      </van-count-down>
    </div>

    <div class="page_theme">
      <div class="box_1">
        <van-row class="user_statistics">
          <van-col :span="goodList.length?6:8" class="item">
            <label>{{hd.total_user}}</label>
            <span>已参与</span>
          </van-col>
          <van-col :span="goodList.length?6:8" class="item">
            <label>{{hd.tj_num}}</label>
            <span>已中奖</span>
          </van-col>
          <van-col span="6" class="item" v-if="goodList.length>0">
            <label>{{hd.seckill_tj_num}}</label>
            <span>已购买</span>
          </van-col>
          <van-col :span="goodList.length?6:8" class="item">
            <label>{{hd.amount}}</label>
            <span>已阅读</span>
          </van-col>
        </van-row>
        <div class="cjnum">
          <span v-if="config.cj_num>0">您还有 {{config.cj_num}} 次抽奖机会！</span>
          <span v-else>您的抽奖次数已用完</span>
        </div>
      </div>
      <div class="box_2"></div>
      <div class="box_3"></div>
      <div class="gList" v-if="hd.is_showgood">
        <van-row type="flex">
          <van-col span="12" v-for="item in gList" :key="item.id">
            <div class="img">
              <p><img :src="item.img"/></p>
              <p class="title ellipsis">{{item.title}}</p>
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="box_5"></div>
      <van-row class="shopList">
        <van-col span="24" v-for="item in hd.shopInfo.contact" :key="item.id" class="item">
          <p class="name">{{item.name}}</p>
          <p class="mobile">{{item.mobile}}<a :href="'tel:'+item.mobile">拨打</a></p>
          <p class="address">{{item.address}}</p>
        </van-col>
      </van-row>
    </div>
    
    <div class="container">
      <div class="index_box" v-if="hd.total_user>0">
        <div class="h4">已有 <em>{{hd.total_user}}</em> 人参与</div>
        <userList :hdid="hd.id"/>
      </div>
      
      <goodList :type="2" :dataList="goodList" :data="hd" v-if="goodList.length"/>
      
      <div class="index_box" v-if="hd.seckill_tj_num>0">
        <div class="h4">已有 <em>{{hd.seckill_tj_num}}</em> 人购买</div>
        <orderuser :hdid="hd.id" :order_type="1"/>
      </div>
      
      <div class="textContent index_box" v-html="hd.content" v-if="hd.content != null && hd.content != ''"></div>

    </div>
  </div>
</template>

<script>
  import goodList from '@/pages/wx/components/goodList'
  import userList from '@/pages/wx/components/userList'
  import orderuser from '@/pages/wx/components/orderuserList'
  import {mapGetters} from "vuex";
  export default {
    props: {
      hd:Object,
      config:Object,
      goodList:Array,
      gList:Array
    },
    components: {goodList,userList,orderuser},
    data() {
      return {
        time:0,
        cOption:{
          SHAKE_THRESHOLD:8000,
          last_update:0,
          last_x:0,
          last_y:0,
          last_z:0
        }
      }
    },
    computed:{
      ...mapGetters(['snapshotuser']),
    },
    activated() {
      this.setBgColor()
    },
    created(){
      this.setBgColor()
      if(this.snapshotuser==1){
        return false
      }
      this.getPermission()
  
      if(window.DeviceMotionEvent) {
        window.addEventListener('devicemotion',this.deviceMotionHandler,false);
      }
    },
    mounted() {
      this.time = new Date(this.hd.end_time).getTime() - new Date().getTime();

    },
    methods: {
      setBgColor(){
        if(this.hd.bgcolor==null || this.hd.bgcolor==''){
          document.querySelector('body').setAttribute('style', 'background:#b72c31')
        }
      },
      getPermission(){
        var ua = navigator.userAgent.toLowerCase();
        if(ua.indexOf("like mac os x") > 0){
          var reg = /os [\d._]*/gi ;
          var verinfo = ua.match(reg) ;
          var version = (verinfo+"").replace(/[^0-9|_.]/ig,"").replace(/_/ig,".");
          if (parseFloat(version) >= 13.3) {  //对13.3以后的版本处理,包括13.3
            DeviceMotionEvent.requestPermission().then(permissionState => {
              if (permissionState === 'granted') { //已授权
                window.addEventListener('devicemotion',this.deviceMotionHandler,false);
              } else if(permissionState === 'denied'){// 打开的链接不是https开头
                alert("当前IOS系统拒绝访问动作与方向，请退出微信，重新进入活动页面获取权限！")
              }
            }).catch((err)=>{
              this.$dialog.alert({
                message: '由于IOS系统需要手动获取访问动作与方向的权限，为了保证正常运行，请在访问提示中点击允许！',
              }).then(() => {
                this.ios13granted()
              });
            });
          }else{  //13.3以前的版本
            window.addEventListener('devicemotion',this.deviceMotionHandler,false);
          }
        }
      },
      ios13granted(){
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
          DeviceMotionEvent.requestPermission().then(permissionState => {
            if (permissionState === 'granted') {
            } else if(permissionState === 'denied'){// 打开的链接不是https开头
              alert("当前IOS系统拒绝访问动作与方向，请退出微信，重新进入活动页面获取权限！")
            }
          })
        }
      },
      deviceMotionHandler(eventData){
        var x=0, y=0, z=0
        var acceleration =eventData.accelerationIncludingGravity;
        var curTime = new Date().getTime();

        if ((curTime-this.cOption.last_update)> 10) {
          var diffTime = curTime -this.cOption.last_update;
          this.cOption.last_update = curTime;
          x = acceleration.x;
          y = acceleration.y;
          z = acceleration.z;
          var speed = Math.abs(x +y + z - this.cOption.last_x - this.cOption.last_y - this.cOption.last_z) / diffTime * 10000;
          if (speed > this.cOption.SHAKE_THRESHOLD) {
            this.onClickElement()
          }
          this.cOption.last_x = x;
          this.cOption.last_y = y;
          this.cOption.last_z = z;
        }
      },
      onClickElement(){
        this.$emit('onPoint',{type:6})
      },
    }
  }
</script>
<style lang="less">
  @import "./css/template1.less";
</style>
